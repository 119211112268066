<template>
  <div class="tw-bg-white tw-p-4 tw-relative tw-h-3/4 tw-w-full tw-pb-60">
    <div class="header">
      <b-col cols="2">
        <p class="text-header">Create Service</p>
      </b-col>
    </div>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.name"
              class="input"
              :textFloat="`ServiceName`"
              placeholder="ServiceName"
              type="text"
              name="name"
              isRequired
              :isValidate="$v.form.name.$error"
              :v="$v.form.name"
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-3">
            <InputText
              v-model="form.urlKey"
              class="input"
              :textFloat="`urlKey`"
              placeholder="urlKey"
              type="text"
              name="name"
              isRequired
              :isValidate="$v.form.urlKey.$error"
              :v="$v.form.urlKey"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          ><div class="my-3">
            <AutocompleteCustom
              v-model="form.brandId"
              ref="AutocompleteCustom"
              id="id"
              label="Brand"
              placeholder="Brand"
              dataListID="dataListID"
              valueField="id"
              showValue=""
              :optionsList="optionBrand"
              @handleInput="handleInputBrand"
              :isRequired="true"
              :isValidate="$v.form.brandId.$error"
              :v="$v.form.brandId"
            /></div
        ></b-col>
        <b-col
          ><div class="my-3">
            <AutocompleteCustom
              v-model="form.categoryId"
              ref="AutocompleteCustom"
              id="id"
              label="Category"
              placeholder="Category"
              dataListID="dataListID"
              valueField="id"
              showValue=""
              :optionsList="optionCategory"
              @handleInput="handleInputCategory"
              :isRequired="true"
              :isValidate="$v.form.categoryId.$error"
              :v="$v.form.categoryId"
            /></div
        ></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="my-3">
            <InputText
              v-model="form.eventGroupPrice"
              class="input"
              :textFloat="`Price`"
              placeholder="Price"
              type="number"
              name="price"
              isRequired
            /></div
        ></b-col>
      </b-row>
      <!-- <b-button
        class="tw-absolute tw-bottom-2 tw-right-8 tw-w-24 tw-h-10"
        @click="onClickCreate"
        :disabled="this.$v.form.$error || isLoading"
        >Create</b-button
      > -->
      <div class="footer-save" @click="onClickCreate">
        <b-button class="btn-save">
          Save
          <b-spinner
            class="m-0 ml-1"
            label="Spinning"
            small
            v-if="isLoading"
          ></b-spinner>
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
export default {
  name: "CreateServicePage",
  components: { AutocompleteCustom },
  data() {
    return {
      //state option
      optionBrand: [],
      optionCategory: [],

      //state form
      form: {
        name: "",
        urlKey: "",
        brandId: "",
        categoryId: "",
        eventGroupPrice: 0,
      },

      isLoading: false,
    };
  },
  mounted() {
    this.getOptionBrand();
    this.getOptionCategory();
  },
  validations() {
    return {
      form: {
        name: { required },
        urlKey: { required },
        brandId: { required },
        categoryId: { required },
        eventGroupPrice: { required, minValue: minValue(0) },
      },
    };
  },
  computed: {},
  methods: {
    async getOptionBrand(keyword) {
      const brandData = await this.$services.master.getOptionBrand(keyword);
      if (brandData.result === 1) {
        this.optionBrand = brandData.detail;
      }
    },
    async getOptionCategory(keyword) {
      const categoryData = await this.$services.master.getOptionCategory(
        keyword
      );
      if (categoryData.result === 1) {
        this.optionCategory = categoryData.detail;
      }
    },
    async onClickCreate() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.isLoading = true;
      const respone = await this.$services.eventgroup.createEventGroup(
        this.form
      );
      this.isLoading = false;
      if (respone.result === 1) {
        const id = respone.detail;
        this.$router.replace(`/service/detail/${id}`);
      } else {
      }
    },
    handleInputBrand(val) {
      setTimeout(() => {
        this.getOptionBrand(val);
      }, 1000);
    },
    handleInputCategory(val) {
      setTimeout(() => {
        this.getOptionCategory(val);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  .item {
    color: var(--secondary-color);
  }
}
.text-header {
  color: #092d53;
  font-weight: 600;
  font-size: 20px;
  width: 300px;
  text-transform: uppercase;
}
.footer-save {
  position: absolute;
  bottom: 10px;
  right: 10px;

  .btn-save {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--secondary-color);
  }
}
</style>
